
import Vue from "vue";
import MentorcastCertificateData from "@/components/Certificate/MentorcastCertificateData.vue";
import ShareDropdown from "@/components/ShareDropdown.vue";
import { mapGetters } from "vuex";
//@ts-ignore
import html2pdf from "html2pdf.js";

export default Vue.extend({
  name: "MentorcastCertificatePage",
  components: {
    MentorcastCertificateData,
    ShareDropdown
  },
  data: function() {
    return {
      url: `${process.env.VUE_APP_HOST_URL}/mentorcasts/${this.$route.params.id}/certificate`
    };
  },
  computed: {
    ...mapGetters({
      certificate: "mentorcast/getCertificate",
      settings: "certificates/getSettings",
      profile: "profile/getProfile"
    })
  },
  mounted() {
    this.$store.dispatch("certificates/GET_SETTINGS");
    this.getCertificate();
  },
  methods: {
    getCertificate() {
      this.$store
        .dispatch("mentorcast/GET_CERTIFICATE", {
          id: this.$route.params.id
        })
        .then(data => {
          console.log("GET_CERTIFICATES>>>>", data);
        });
    },
    /* printThis() {
      window.open(this.certificate.certificate_download_path, "_blank");
    }*/
    async printThis() {
      console.log(
        "this.certificate.certificate_download_path",
        this.certificate.certificate_download_path
      );

      const seletor = "certificate";
      const element = document.getElementById(seletor);

      const options = {
        margin: 0,
        enableLinks: true,
        filename: "Certificate",
        image: {
          type: "jpeg",
          quality: 1
        },
        html2canvas: {
          scale: 2,
          useCORS: true,
          dpi: 96,
          letterRendering: true,
          y: 0,
          scrollY: 0
        },
        jsPDF: {
          unit: "in",
          format: "a4",
          orientation: "portrait"
        }
      };

      html2pdf()
        .set(options)
        .from(element)
        .toPdf()
        .save();
    }
  }
});
